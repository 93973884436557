<template>
  <div>
    <!--top-->
    <div class="top app-wrapper">
      <div class="top-left">
        <!-- <span>上海华谊检验检测技术有限公司</span> -->
        <img src="@/assets/logo.jpg" alt="" />
      </div>
      <div class="top-right">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          router
        >
          <el-menu-item index="Home">首页</el-menu-item>
          <el-menu-item index="FollowUs">关于我们</el-menu-item>
          <el-menu-item index="ServiceArea">服务领域</el-menu-item>
          <el-menu-item index="ServiceItems">服务项目</el-menu-item>
          <el-menu-item index="ReportQuery">报告查询</el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- main -->
    <AppMain />
    <!-- 底部 -->
    <div class="friend-link">
      <div class="friend-main">
        <div class="company-text">
          <div class="gs-jj">
            <h3>上海华谊检验检测技术有限公司</h3>
            <span>
              上海华谊检验检测技术有限公司坐落于上海市，是一家致力于诚信服务大众、专注做好检测的第三方检测机构。上海华谊检验检测技术有限公司自成立以来。依据《检验检测机构资质认定能力评价检验检测机构通用要求》、《检测和校准实验室能力认可准则》，建立了完善的质量保证体系，确定了""创建优质服务，满足质量要求"的质量目标。严格执行国家现行标准、规范及规程，为越来越多的客户提供优质的第三方检测服务!
            </span>
          </div>
          <div class="gs-fw">
            <div class="gs-area">
              <div class="gs-menu-title">服务领域</div>
              <ul class="gs-menu-item">
                <li>材料类</li>
                <li>建材类</li>
                <li>民计民生</li>
                <li>环境类</li>
                <li>其他检测</li>
              </ul>
            </div>
            <div class="gs-project">
              <div class="gs-menu-title">服务项目</div>
              <ul class="gs-menu-item">
                <li>分析服务</li>
                <li>测试服务</li>
                <li>计量校准服务</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppMain from "./components/AppMain.vue";
export default {
  name: "LayOut",
  components: {
    AppMain,
  },

  data() {
    return {
      activeIndex: "Home",
    };
  },
  created() {
    this.setActiveMenu();
  },

  watch: {
    $route() {
      this.setActiveMenu();
    },
  },

  methods: {
    setActiveMenu() {
      const path = this.$route.path;
      switch (path) {
        case "/home":
          this.activeIndex = "Home";
          break;
        case "/FollowUs":
          this.activeIndex = "FollowUs";
          break;
        case "/ServiceArea":
          this.activeIndex = "ServiceArea";
          break;
        case "/ServiceItems":
          this.activeIndex = "ServiceItems";
          break;
        case "/ReportQuery":
          this.activeIndex = "ReportQuery";
          break;
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = keyPath;
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  padding: 0 1.8rem;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .top-left {
    height: 5rem;
    padding: 0.75rem 0;
    span {
      font-size: 1.5rem;
      font-weight: 500;
    }
    img {
      max-height: 100%;
    }
  }
  .top-right {
    height: auto;
    .el-menu {
      border-bottom: unset;
    }
  }
}
@media (max-width: 768px) {
  .top {
    height: 11rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: #fff;
    width: 100%;
    .top-left {
      height: 50%;
      padding: 0.75rem 0;
      span {
        font-size: 1.5rem;
        font-weight: 500;
      }
      img {
        max-height: 100%;
      }
    }
    .top-right {
      width: 100%;
      height: 50%;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        li {
          cursor: pointer;
          font-size: 1.2rem;
          white-space: nowrap;
          padding: unset;
          text-align: center;
        }
      }
    }
  }
}

.friend-link {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 20.625rem;
  background: url("~@/assets/friend.jpg") no-repeat center center;
  background-size: cover;
  overflow: hidden;
  .friend-main {
    max-width: 75rem;
    margin: 3.125rem auto 0;
    padding: 0 0.9375rem;
    color: #fff;
    .company-text {
      display: flex;
      justify-content: space-between;
      padding-right: 2rem;
      .gs-jj {
        flex: 0.6;
        h3 {
          font-size: 1.875rem;
          margin: 0;
          margin-bottom: 1.25rem;
        }
      }
      .gs-fw {
        flex: 0.4;
        display: flex;
        justify-content: space-around;
      }
      .gs-menu-title {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.625rem;
      }
      .gs-menu-item {
        li {
          padding: 0.25rem 0;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .friend-link {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 36rem;
    background: url("~@/assets/friend.jpg") no-repeat center center;
    background-size: cover;
    overflow: hidden;
    .friend-main {
      max-width: 75rem;
      margin: 3.125rem auto 0;
      padding: 0 0.9375rem;
      color: #fff;
      .company-text {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .gs-jj {
          h3 {
            font-size: 1.875rem;
            margin: 0;
            margin-bottom: 1.25rem;
          }
        }
        .gs-fw {
          display: flex;
          justify-content: flex-start;
          margin-top: 4rem;
          .gs-area {
            margin-right: 6rem;
          }
        }

        .gs-menu-title {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 0.625rem;
        }
        .gs-menu-item {
          li {
            padding: 0.25rem 0;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}
</style>
