<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  // computed: {
  //   key() {
  //     return this.$route.path;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
// .app-main {
//   /*84 = navbar+header  */
//   min-height: calc(100vh - 84px);
//   width: 100%;
//   position: relative;
//   overflow: hidden;
//   background-color: #f2f2f6;
// }
// .fixed-header + .app-main {
//   padding-top: 50px;
// }
</style>

<!-- <style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style> -->
