import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import Layout from "@/layout";
const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/Home",
    children: [
      {
        path: "Home",
        name: "Home",
        component: () => import("@/views/home/index"),
        meta: { title: "首页", icon: "pmIcon icon-shouye1" },
      },
      {
        path: "ReportQuery",
        name: "ReportQuery",
        component: () => import("@/views/ReportQuery/index"),
        meta: { title: "报告查询", icon: "pmIcon icon-shouye1" },
      },
      {
        path: "FollowUs",
        name: "FollowUs",
        component: () => import("@/views/FollowUs/index"),
        meta: { title: "关于我们", icon: "pmIcon icon-shouye1" },
      },
      {
        path: "ServiceArea",
        name: "ServiceArea",
        component: () => import("@/views/ServiceArea/index"),
        meta: { title: "服务领域", icon: "pmIcon icon-shouye1" },
      },
      {
        path: "ServiceItems",
        name: "ServiceItems",
        component: () => import("@/views/ServiceItems/index"),
        meta: { title: "服务项目", icon: "pmIcon icon-shouye1" },
      },

      {
        path: "SecurityArticle",
        name: "SecurityArticle",
        component: () => import("@/views/SecurityArticle/index"),
        meta: { title: "报告结果", icon: "pmIcon icon-shouye1" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
